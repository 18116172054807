//// Here you can add other styles
body {
  overflow: auto !important;
}
#zmmtg-root {
  width: auto !important;
  height: auto !important;
  position: relative !important;
  background-color: aqua;
}

#content_container,
.meeting-client,
.meeting-client-inner,
.gallery-video-container__wrap
{
  width: inherit !important;
  height: inherit !important;
  position: relative !important;
  margin: 0 auto !important;
}

//#wc-footer {
//  bottom: auto !important;
//  width: auto !important;
//}

#dialog-join {
  width: auto !important;
}

#sv-active-video, .active-main, #sv-active-speaker-view, .main-layout {
  height: auto !important;
  width: auto !important;
}

.suspension-window {
  transform: translate(-444px, 10px) !important;
}

#dialog-invite {
  display: none;
}

.suspension-window {
  transform: translate(-444px, 10px) !important;
}
/**************/
.media-control-btn {
  margin: 0 auto !important;
  padding: 0 !important;
  width: inherit !important;
}
.meeting-app, #wc-loading {
  width: inherit !important;
  height: inherit !important;
  min-width: 50em;
  min-height: 35em;
}
/**************/
.c-main {
  width: inherit !important;
  margin: 0 !important;
}

.c-sidebar-section {
  background: transparent linear-gradient(180deg, #dde7ff 0%, #e8efff 60%, #f2f6ff 93%, #f2f6ff 100%) 0% 0% no-repeat padding-box;
}
